

/*
 * Date: 2024
 * Description: Main landing page for admin interactions, could introduce stuff here..
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { ReactElement , useContext, useEffect , useState } from "react";
import { Box ,Typography , Paper , Grid , Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

//Interfaces && types
import { i_initial_props, i_promise , i_snack_alert , i_alert_props } from "../interfaces/utility.interface";

//Utilitys
import { f_fetch } from "../api/fetch";

//Components
import Footer from "../components/utility/footer";
import AlertDialog from "../components/utility/alert";

//Constant
import { empty_promise } from "../utils/constant";

//Context
import { MainContext } from "../context/context";
import { inner_container, secondary_container } from "../styles/main.styles";

const Dashboard = ( props : i_initial_props ) : ReactElement => {
    const nav = useNavigate()
    const [ loading , setLoading ] = useState<boolean>(true)
    const [ api_error, setApiError ] = useState<i_snack_alert>({ open : false , promise : empty_promise});

    const { HandleLogout } = useContext(MainContext)
    useEffect(() => {
        const getTimes = async( ) : Promise<i_promise> => {
            setLoading(true)
            const res = await f_fetch('/auth' , 'POST' , true , null)
            if(res.type === 'Success'){
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
            return res
        }
        getTimes()
    },[nav , HandleLogout])

    const handleClosePunches = async() => {
        setLoading(true)
        const res = await f_fetch('/punchs/close' , 'GET' , true , null )
        if(res.type === 'Success'){

        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
        }
        setApiError({ open : true, promise : res })
        setLoading(false)
    }
    const handleTestMail = async() => {
        setLoading(true)
        const res = await f_fetch('/punch/event' , 'GET' , true , null )
        if(res.type === 'Success'){

        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
        }
        setApiError({ open : true, promise : res })
        setLoading(false)
    }
    const handleFetch = async( type : 'schedules' | 'events') => {
        setLoading(true)
        const res = await f_fetch('/config/'+type , 'GET' , true , null )
        if(res.type === 'Success'){
            console.log(res)
        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
        }
        setApiError({ open : true, promise : res })
        setLoading(false)
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple',
        mobile : props.mobile
    }
  return (
    <Box>
        <Box sx={secondary_container}>
            <Box sx={{ ...inner_container, paddingTop : '10vh'}}>
                <Typography sx={{ textAlign : 'center', fontSize : 22 , fontWeight : 800}}> Dashboard </Typography>
                <Grid container>
                    <Grid item xs={3}>

                    </Grid>          
                    <Grid item xs={6}>
                            <Paper sx={{ padding : '3vh'}}>
                                <Box>Admin pages... use the top buttons navigation</Box>
                                <Box>Feel free to report any bug or features required</Box>
                            </Paper>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh'}}>
                        <Button variant="contained" sx={{ fontSize : 22}} onClick={() => handleClosePunches() }>Test Cron close event</Button>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh'}}>
                        <Button variant="contained" sx={{ fontSize : 22}} onClick={() => handleFetch('schedules') }>Get Schedules</Button>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh'}}>
                        <Button variant="contained" sx={{ fontSize : 22}} onClick={() => handleFetch('events') }>Get Events</Button>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh'}}>
                        <Button variant="contained" sx={{ fontSize : 22}} onClick={() => handleTestMail() }>Test email event</Button>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </Box>
        </Box>
        <AlertDialog {...alert_props} />
        <Footer type={'center'} {...props}/>
    </Box>
  );
}

export default Dashboard;