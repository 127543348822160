
/*
 * Date: 2024
 * Description: Reusable utility functions
 * Author: Philippe Leroux @ skitsc
 */

//Interfaces && types
import { t_user_fields } from "../types/types";
import { i_period_data, i_promise , i_search_filter } from "../interfaces/utility.interface";
import { GridValueFormatterParams } from "@mui/x-data-grid/models"

const f_set_first_letter = (input: string): string => {
    if (input.length === 0)   return input
    const firstLetter = input[0].toUpperCase();
    const restOfString = input.slice(1);
    return firstLetter + restOfString;
}
const f_empty_promise = (): i_promise => {
    const promise: i_promise = {
        message: "",
        type: "Failed",
        data: [],
    };
    return promise;
};
const delay = (ms : number) : Promise<void> => new Promise(res => setTimeout(res, ms));

const f_validate_num = ( input: number ) : 0 | 1 => {
    if (input === 0 || input === 1)  return input;
    return 0;
}
const f_validateField = (field: string): field is t_user_fields => {
    return ['email', 'first_name', 'last_name', 'phone_one', 'phone_two' , 'address', 'password' , 'path'].includes(field as t_user_fields);
};
const isEmptyObject = (obj: Record<string, any>): boolean => {
    return Object.keys(obj).length === 0;
}
const f_is_Today = (date : Date) => {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) return true;
    return false;
}

const f_is_Year = (date : Date) => {
    const today = new Date();
    if (today.getFullYear() === date.getFullYear())return true;
    return false;
}
const pad = (value : number) => {
    if(value < 10) return '0' + value;
    return value;
}
const f_timestamp_to_date = ( timestamp : number | GridValueFormatterParams | string , type : 'short' | 'long' ) => {
    if(timestamp == null){
        return 0
    }else{
        if(Number(timestamp)){
            var timez : number = Number(timestamp)
            const UNIX_timestamp = new Date(timez * 1000)
            const is_today = f_is_Today(UNIX_timestamp)
            const is_year = f_is_Year(UNIX_timestamp)
            var time : string = '';
            var a : Date = new Date(UNIX_timestamp);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year : number = a.getFullYear();
            var month : string = months[a.getMonth()];
            var short_month : number = a.getMonth() + 1;
            var date : number = a.getDate();
            var hour : number = a.getHours();
            var min : string | number = pad(a.getMinutes());
            if(type !== undefined && type === 'short'){
                if(!is_today){
                    var str_year = String(year)
                time =  date + '/' + short_month + '/' + str_year.slice(2); 
                }else{
                    var moment = 'A.M'
                    if(hour > 12){
                        moment = 'P.M'
                        hour = hour - 12
                    }
                    time = hour + ':' + min + ' ' + moment;
                }
                return time
            }

            if(is_today === undefined || is_year === undefined){
                time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
                return time
            }
            if(is_today && is_year){
                time = hour + ':' + min;
                return time
            }
                time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
            return time; 
        }
    }
   
}
const f_return_color = ( select : number ) => {
    if(select === 1) return '#f44336'
    if(select === 2) return '#304ffe'
    if(select === 3) return '#b388ff'
    if(select === 4) return '#ffeb3b'
    if(select === 5) return '#4caf50'
    if(select === 6) return '#ef6c00'
    return '#000000'
}
const CheckEnv = ( ) : boolean => {
    if(process.env.REACT_APP_ENV === 'dev') return true
    return false
}
const f_format_phone_cute = ( ugly_phone : string ) => {
    var cute_phone = ugly_phone;
    var l = cute_phone.length
    if(l > 7 && l === 11)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{3})(\d{1,4})$/, "$1-($2)-$3-$4")
    if(l > 7 && l === 10)cute_phone = ugly_phone.replace(/(\d{3})(\d{3})(\d{1,4})$/, "($1)-$2-$3")
    if(l > 4 && l <= 7)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{1,3})$/, "$1-($2)-$3")
    if(l > 1 && l <= 4)cute_phone = ugly_phone.replace(/(\d{1})(\d{1,3})$/, "$1-($2)")
    if(l === 1)cute_phone = ugly_phone.replace(/(\d{1})$/, "$1-")
    return cute_phone 
}
const f_set_local_key = (key : string , input_value : any, time : number ) => {
    const now = new Date()
    const item = {
        value: input_value,
        expiry: now.getTime() + time,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const f_get_local_key = ( key : string ) => {
    const itemStr = localStorage.getItem(key)
    if(!itemStr) return null
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        f_kill_storage(key)
        return null
    }
    return item.value
}
const f_kill_storage = ( key : string ) => {
    localStorage.removeItem(key)
}

const f_darken_color = (hex : string, factor : number) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    const newR = Math.round(r * (1 - factor));
    const newG = Math.round(g * (1 - factor));
    const newB = Math.round(b * (1 - factor));
    const clamp = (value : number) => Math.min(255, Math.max(0, Math.round(value)));
    const newHex = `#${clamp(newR).toString(16).padStart(2, '0')}` +
                  `${clamp(newG).toString(16).padStart(2, '0')}` +
                  `${clamp(newB).toString(16).padStart(2, '0')}`;

    return newHex;
}
const f_lighten_hex_color = (hex: string, percent: number): string  =>{
    percent = Math.min(100, Math.max(0, percent));
    let r: number = parseInt(hex.slice(1, 3), 16);
    let g: number = parseInt(hex.slice(3, 5), 16);
    let b: number = parseInt(hex.slice(5, 7), 16);
    r += Math.round((255 - r) * (percent / 100));
    g += Math.round((255 - g) * (percent / 100));
    b += Math.round((255 - b) * (percent / 100));
    const newHex = `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;

    return newHex;
}
const f_make_cute_date = ( date : Date ) : string => {
    const hours = date.getHours();
    const amOrPm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
    return formattedTime;
}
const f_encode_query_data = (filter: i_search_filter): URLSearchParams => {
    const params = new URLSearchParams();
    Object.entries(filter).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
            value.forEach((item) => params.append(`${key}[]`, item.toString()));
        } else {
            params.append(key, value.toString());
        }
        }
    });
    return params;
};
const formatSIN = ( sin : string) => {
    const cleanSIN = sin.replace(/\D/g, '');
    return cleanSIN.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
};
const countDigits = ( value : string) => {
    const digitRegex = /\d/g;
    const matches = value.match(digitRegex);
    return matches ? matches.length : 0;
};
const getPayPeriod = (date : Date , biweekly : boolean): i_period_data => {
    const year: number = date.getFullYear();
    const yearStart: Date = new Date(year, 0, 1);
    const difference: number = date.getTime() - yearStart.getTime();
    const daysPassed: number = Math.ceil(difference / (1000 * 3600 * 24));
    let period: number;
    let monday: Date;
    let sunday: Date;
    if (daysPassed <= 6) {
        period = 1;
        monday = new Date(yearStart);
        sunday = new Date(yearStart.getTime() + (6 * 24 * 3600 * 1000));
    } else {
        if (biweekly) {
            const totalDays = daysPassed - 6;
            const biweeklyPeriods = Math.floor((totalDays - 1) / 14);
            const remainingDays = totalDays - biweeklyPeriods * 14;
            period = biweeklyPeriods * 2 + 1;
            if (remainingDays <= 6) {
                monday = new Date(yearStart.getTime() + (biweeklyPeriods * 14 * 24 * 3600 * 1000));
            } else {
                period++;
                const daysToMonday = 7 - (yearStart.getDay() || 7);
                monday = new Date(yearStart.getTime() + (daysToMonday * 24 * 3600 * 1000) + ((biweeklyPeriods) * 14 * 24 * 3600 * 1000));
            }
            sunday = new Date(monday.getTime() + (13 * 24 * 3600 * 1000)); // Sunday is 13 days later
        } else {
            const weeklyPeriods = Math.floor((daysPassed - 6) / 7);
            period = weeklyPeriods + 1;
            const daysToMonday = 7 - (yearStart.getDay() || 7);
            monday = new Date(yearStart.getTime() + (daysToMonday * 24 * 3600 * 1000) + ((weeklyPeriods) * 7 * 24 * 3600 * 1000));
            sunday = new Date(monday.getTime() + (6 * 24 * 3600 * 1000)); // Sunday is 6 days later
        }
    }
    console.log(period , monday , sunday)
    return {
        period: period,
        monday: monday.valueOf(),
        sunday: sunday.valueOf()
    };
}

const getPayAllPeriods = (year: number, biweekly: boolean): i_period_data[] => {
    const yearStart: Date = new Date(year, 0, 1);
    const payPeriods: i_period_data[] = [];
    let currentMonday: Date = new Date(yearStart);
    let currentSunday: Date = new Date(yearStart.getTime() + (6 * 24 * 3600 * 1000)); // First Sunday
    let period : number= 0;
    while (currentMonday.getFullYear() === year) {
        payPeriods.push({
            period: period,
            monday: currentMonday.getTime(),
            sunday: currentSunday.getTime()
        });

        // Move to the next pay period
        if (biweekly) {
            currentMonday = new Date(currentSunday.getTime() + (1 * 24 * 3600 * 1000)); // Move to next Monday
            currentSunday = new Date(currentMonday.getTime() + (13 * 24 * 3600 * 1000)); // Move to Sunday two weeks later
        } else {
            currentMonday = new Date(currentSunday.getTime() + (1 * 24 * 3600 * 1000)); // Move to next Monday
            currentSunday = new Date(currentMonday.getTime() + (6 * 24 * 3600 * 1000)); // Move to next Sunday
        }
        
        period++;
    }
    // console.log('Intercept getAllPayperiods: ', payPeriods);
    return payPeriods;
}

const getPayPeriodFromDate = (periods: i_period_data[], timestamp: number): i_period_data | null => {
    for (var x : number = 0; x < periods.length; x++) {
        if (timestamp >= periods[x].monday && timestamp <= periods[x].sunday) {
            return periods[x];
        }
    }
    return null; 
}

const getCurrentYear = (): number => {
  const today = new Date();
  return today.getFullYear();
};

const formatTime = (date : Date): string => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds} `;
};

const CurrentDate = (date: Date = new Date()) : string  =>{
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = ["January", "February", "March", "April", "May", "June",  "July", "August", "September", "October", "November", "December"]
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();
    return `${dayOfWeek} the ${dayOfMonth} ${month} ${year}`;
}

const formatElapsedTime = (elapsed: number): string => {
    const hours = String(Math.floor(elapsed / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((elapsed % 3600) / 60)).padStart(2, '0');
    const seconds = String(elapsed % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds} `;
};
const millisecondsToTime = (ms: number): string => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const pad = (num: number) => num.toString().padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};
const formatTimeDifference = (timestamp1: number, timestamp2: number): string => {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    let difference = Math.abs(timestamp1 - timestamp2);
    const days = Math.floor(difference / msPerDay);
    difference = difference % msPerDay;
    const hours = Math.floor(difference / msPerHour);
    difference = difference % msPerHour;
    const minutes = Math.floor(difference / msPerMinute);
    let result = '';
    if (days > 0) result += `${days}:`;
    result += `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return result;
}
const parseDateTime = (time: string): Date | null  =>{
    const match = time.match(/^([0-9]|0[1-9]|1[0-2]):([0-5][0-9]) ([APap][Mm])$/);
    if (!match) return null;
    let hours = parseInt(match[1]);
    const minutes = parseInt(match[2]);
    const period = match[3].toUpperCase();
    if (period === "PM" && hours < 12) {
        hours += 12;
    } else if (period === "AM" && hours === 12) {
        hours = 0;
    }
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
}
const isFirstTimeEarlier = (time1: string, time2: string): boolean =>{
    const date1 = parseDateTime(time1);
    const date2 = parseDateTime(time2);
    if (date1 && date2) {
        return date1 <= date2 ? true : false;
    }
    return false
}
const isInDateRange = (initial_date: string, in_t: number, out_t: number): boolean => {
    const [year, month, day] = initial_date.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));
    const startOfDay = (new Date(date.setUTCHours(0, 0, 0, 0)).getTime() / 1000);
    const endOfDay = (new Date(date.setUTCHours(23, 59, 59, 999)).getTime() / 1000);
    if(in_t < startOfDay) return false
    if (out_t === 0 && in_t < startOfDay) return true;
    if (out_t === 0 && in_t >= startOfDay && in_t <= endOfDay) return true;
    if (in_t >= startOfDay && out_t <= endOfDay && out_t !== 0) return true;
    if (in_t < startOfDay && out_t >= startOfDay && out_t <= endOfDay && out_t !== 0) return true;
    if (in_t >= startOfDay && in_t <= endOfDay && out_t > endOfDay && out_t !== 0) return false;
    return false;
};
export { f_empty_promise, delay , isInDateRange, isEmptyObject, isFirstTimeEarlier, f_validate_num , f_validateField , f_timestamp_to_date , f_set_first_letter , f_encode_query_data , countDigits , formatSIN, getPayPeriod, getPayAllPeriods, getPayPeriodFromDate, formatTime, CurrentDate, formatElapsedTime,
   f_return_color , CheckEnv  , f_format_phone_cute , f_set_local_key , f_get_local_key , f_kill_storage , f_darken_color , f_make_cute_date , f_lighten_hex_color , getCurrentYear , millisecondsToTime , formatTimeDifference };
