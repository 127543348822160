


/*
 * Date: 2024
 * Description: Public login page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, useContext , ReactElement , ChangeEvent , MouseEvent } from "react";
import { Box ,TextField ,IconButton ,Typography ,Button ,Grid } from "@mui/material";
import { LoadingButton } from '@mui/lab';

//Contexts
import { MainContext, ThemeContext } from "../context/context";
import { SocketContext } from "../context/socket.context";

//Middlewares
import { m_validate_login } from "../validation/main.middleware";

//Api
import { f_fetch } from "../api/fetch";

//Icons And button
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from '@mui/icons-material/Edit';

//Styles
import { FormStyles } from "../styles/main.styles"

//Component
import Alert from "../components/utility/alert";

//Interfaces && Types
import { i_user_login, i_user_login_errors } from "../interfaces/user.interface";
import { i_alert_props, i_initial_props } from "../interfaces/utility.interface";
import { i_snack_alert } from "../interfaces/utility.interface";

//Utilities
import { empty_promise } from "../utils/constant";

//Middlewares
import { m_force_str } from "../validation/utility.middleware";

const PublicLogin = ( props : i_initial_props ) : ReactElement  => {

    //Contexts
    const { HandleLogin , config } = useContext(MainContext);
    const { mode } = useContext(ThemeContext);
    const socket = useContext(SocketContext)
    //Main states
    const [ loading , setLoading ] = useState<boolean>(false);
    const [ api_errors , setApiErrors ] = useState<i_snack_alert>({ open: false, promise: empty_promise });
    //Form
    const [ code, setCode ] = useState<string>("");
    const [ nip, setNip] = useState<string>("");
    const [ visible, setVisible ] = useState<boolean>(false);
    const [ step , setStep ] = useState<number>(1);
    //Errors  
    const [ form_errors, setFormErrors ] = useState<i_user_login_errors>({ code: '', nip: ''});
    
    const handleOnSubmit = async(e : any) => {

        e.preventDefault();            
        const clean_nip = nip 
        const clean_login : i_user_login = { code : code, nip : clean_nip}
        if(step === 2){
            setLoading(true);
            const [ valid , error , field ] = m_validate_login(clean_login , step)
            if(valid){
                const res = await f_fetch("/punch", 'POST' , true , clean_login);
                if(res.type === "Success"){
                    if(res.data._id !== undefined) {
                        HandleLogin(res.data);
                        socket.emit('join', { roomId : res.data.type , user : res.data , socket_id : socket.id })
                    }
                }
                setApiErrors({ open: true, promise : res });
            }else{
                setFormErrors({...form_errors, [field] : error })
            }
            setLoading(false);
        }else{
            const [ valid , error , field ] = m_validate_login(clean_login, step)
            if(code.length !== 0 && valid){
                setStep(2)
            }else{
                setFormErrors({...form_errors, [field] : error })
            }
            
        }
    };
    useEffect(() => {
        //Add validation HERE !
        if(form_errors.code !== '' && m_force_str(code) === '') setFormErrors({...form_errors, code : '' })
        if(form_errors.nip !== '' && Number(nip)) setFormErrors({...form_errors, nip : '' })
    }, [form_errors , code, nip]);

    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : empty_promise }),
        type : 'full',
        mobile : props.mobile
    }

    useEffect(() => {  
        const handleKeyPress = (event : any) => {
            if (event.key === 'Enter')  handleOnSubmit(event);
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [loading , code , nip]);
    const handleClick = ( e : MouseEvent<HTMLButtonElement>) => {
        var V = e.currentTarget.textContent
        var new_nip : string = nip?.toString() !== undefined ? nip.toString() : ''
        if(V !== 'clear' &&  V !== 'X'){
            new_nip = new_nip + e.currentTarget.textContent
        }else{
            if(V === 'X'){
                new_nip = new_nip.slice(0, -1)
            }else{
                new_nip = ''
            }
        }
        setNip(new_nip)
    }
    const hideNip = ( value : string) : string => {
        return '*'.repeat(value.length);
    }
  const pre_path = process.env.REACT_APP_API_URL + '/api/web/public/config/'
  const center_box = { display : 'flex' , justifyContent : 'center', marginBottom : '1vh'}
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" , backgroundImage : mode === "Dark" ? "url('./img/bg_dark.jpg')" :"url('./img/bg.jpg')" ,backgroundSize: "cover",  backgroundPosition: "center"}}>
        <Box  sx={{ width : '400px',borderRadius: 1 , padding: '2em'}}>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", pb: 2}}>
                    <Box component={'img'} alt="clutch punch logo" sx={{ width: '150px' , marginBottom : '1vh'}} src={config.path !== '' ? pre_path + config.path : './img/placeholder.png'}></Box>
                        <Typography sx={{ fontWeight : '600' , marginBottom : '1vh'}} component="h1" variant="h5">Clutch Punch</Typography>
                        <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">Welcome back! Please enter your credentials</Typography>
                    </Box>
                    <Box component={'form'}>
                        {step === 2 &&
                            <Box sx={{ display : 'flex'}}>
                                <Typography sx={{ fontWeight : 800 , fontSize : 18}}>User code : { code }</Typography>
                                <IconButton sx={{ height : 28 , marginLeft : 'auto' , marginRight : 2}} onClick={() => setStep(1)}><EditIcon/></IconButton>
                            </Box>
                        }
                        <Box sx={{ display : 'flex'}}>
                            <Typography sx={{ fontWeight : 800 , fontSize : 18}}>{step === 1 ? "Enter your User code" : "Enter your nip :"  }</Typography>
                            {step === 2 && <>
                            <Typography sx={{ marginLeft : '10px' , fontWeight : 800 , textDecoration : 'underline' , fontSize : 18}}>{visible ? nip : hideNip(nip) }</Typography>
                            <IconButton onClick={() => setVisible(visible ? false : true)} sx={{ height : 28 , marginLeft : 'auto' , marginRight : 2}}>{visible ? <VisibilityOffIcon/> : <VisibilityIcon/> }</IconButton>
                            </>}
                        </Box>
                        {step === 1 && 
                            <TextField  fullWidth size="small" sx={FormStyles.textfield} autoComplete="new-password"
                            value={code} helperText={form_errors.code} error={form_errors.code !== "" ? true : false}
                            InputLabelProps={{ shrink: true }} 
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setCode(e.target.value)}/>
                        }
                        {step === 2 && 
                            <Grid container p={2}>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>1</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>2</Button>
                                </Grid>
                                <Grid item xs={4}sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>3</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>4</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>5</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>6</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>7</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>8</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>9</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>clear</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>0</Button>
                                </Grid>
                                <Grid item xs={4} sx={center_box}>
                                    <Button variant="contained" onClick={handleClick}>X</Button>
                                </Grid>
                            </Grid>
                        }
                        <LoadingButton loading={loading} disabled={loading} fullWidth variant="contained" color="primary" onClick={(e) => handleOnSubmit(e)}>{step === 1 ? "Next" : "Sign in "}</LoadingButton>
                    </Box>
                </Box>
            </Box>
          <Alert {...alert_props} />
      </Box>
  );
}

export default PublicLogin