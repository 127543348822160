
/*
 * Date: 2024
 * Description: Form content for advanced configurations
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ChangeEvent, ReactElement } from "react";
import { Box , Grid, TextField , Typography , Checkbox , FormControlLabel, Tooltip} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
//Interfaces && types

//Styles
import { sub_title } from "../../../styles/form.styles";
import { i_adv_config_form } from "../../../interfaces/setting.interface";

//Const

//Icons
dayjs.extend(customParseFormat);

const AppConfigAdvancedInputs = ( props : i_adv_config_form ) : ReactElement => {
    const F = props.form
    const E = props.errors
    const row_modeling = { height : '65px' , marginBottom : '8px'}
    const item_width = { width : '265px'}
    const handleChecked = ( event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        props.setForm({...F, [name] : checked })
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (/^\d*$/.test(value))  props.setForm({ ...F, [name]: value });
    };
    const handleTime = (value: Dayjs | null, type: 'start' | 'end' | 'cron'): void => {
        if (value) {
            const formattedTime = value.format('hh:mm A');
            if (type === 'start') {
                props.setForm({...F, working_start : formattedTime })
            } else if (type === 'end') {
                props.setForm({...F, working_end : formattedTime })
            } else if (type === 'cron') {
                props.setForm({...F, cron_close_time : formattedTime })
            }
        }
    };
    
    const title = { ...sub_title , marginRight : 'auto'}
    return ( 
    <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , display : 'flex' , justifyContent : 'center' }} id={'app_config_inputs'} >
         <Grid container>
            <Grid item xs={3} sx={row_modeling}>
                <Typography sx={[title , { marginTop : '10px'}]}>Triggers</Typography>
            </Grid>
            <Grid item xs={9}>
                <Tooltip title={'Allow cron to close open punches after working hours'}>
                    <FormControlLabel sx={[item_width , { marginRight : '25px' }]} label="Overide open hours" control={<Checkbox checked={F.overide_working_hours} name={'overide_working_hours'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
                <Tooltip title={'Allow warning email for unclosed punch after working hours'}>
                    <FormControlLabel sx={item_width} label="Allow emails" control={<Checkbox checked={F.cron_email} name={'cron_email'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
                <Tooltip title={'Force user to take a photo before action ( with phome camera or webcam )'}>
                    <FormControlLabel sx={[item_width , { marginBottom : '1vh'}]} label="Force camera" control={<Checkbox checked={F.force_picture} name={'force_picture'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
            </Grid>
            <Grid item xs={3} sx={row_modeling}>
                <Typography sx={[title , { marginTop : '10px'}]}>Open working hours *</Typography>
            </Grid>
            <Grid item xs={9}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField sx={[ item_width , { marginRight : '10px'} ]} helperText={props.errors.working_start} label={'Start'} maxTime={dayjs(F.working_end , 'h:mm A')} value={ dayjs(F.working_start , 'h:mm A')}ampm={true} onChange={(e) => handleTime(e , 'start')} />
                    <TimeField sx={item_width} label={'End'} helperText={props.errors.working_end}  minTime={dayjs(F.working_start , 'h:mm A')} value={ dayjs(F.working_end , 'h:mm A')} ampm={true} onChange={(e) => handleTime(e , 'end')} /> 
                </LocalizationProvider>  
            </Grid>
            <Grid item xs={3} sx={row_modeling}>
                <Typography sx={[title , { marginTop : '10px'}]}>Cron closing event's</Typography>
            </Grid>
            <Grid item xs={9}>
                <Tooltip title={'Allow cron to close open punches after working hours'}>
                    <FormControlLabel sx={[ item_width ,  { marginRight : '20px' }]} label="Allow cron close" control={<Checkbox checked={F.cron_close} name={'cron_close'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
                {F.cron_close &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField sx={item_width} label={'Cron close time'} helperText={props.errors.cron_close_time}  minTime={dayjs(F.working_end , 'h:mm A')} value={ dayjs(F.cron_close_time , 'h:mm A')} ampm={true} onChange={(e) => handleTime(e , 'cron')} /> 
                    </LocalizationProvider>
                }
            </Grid>
            <Grid item xs={3} sx={row_modeling}>
                <Typography sx={[title , { marginTop : '10px'}]}>Break event's</Typography>
            </Grid>
            <Grid item xs={9}>
                <Tooltip title={'Allow break notification event if overpass the allowed time value'}>
                    <FormControlLabel sx={[ item_width ,  { marginRight : '20px' }]} label="Allow break notification" control={<Checkbox checked={F.break_warning} name={'break_warning'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
                { F.break_warning &&
                    <TextField sx={item_width} label={'Break max minutes'} name={'break_duration'} helperText={E.break_duration} variant="outlined" value={F.break_duration} onChange={handleChange} />
                }
            </Grid>
            <Grid item xs={3} sx={row_modeling}>
                <Typography sx={[title , { marginTop : '10px'}]}>Lunch event's</Typography>
            </Grid>
            <Grid item xs={9}>
                <Tooltip title={'Allow lunch notification event if overpass the allowed time value'}>
                    <FormControlLabel sx={[ item_width , { marginRight : '20px' }]} label="Allow lunch notification" control={<Checkbox checked={F.lunch_warning} name={'lunch_warning'} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }}/>} />
                </Tooltip>
                {F.lunch_warning &&
                    <TextField sx={item_width} name={'lunch_duration'} label={'Lunch max minutes'} helperText={E.break_duration} variant="outlined" value={F.lunch_duration} onChange={handleChange} />
                }
            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={6} mt={5}>
                <LoadingButton sx={{ display : 'block', border : '1px solid gray' , maxWidth : '540px' , marginLeft : 'auto' , marginRight : 'auto'}} loading={false} fullWidth variant="contained" color="primary" onClick={() =>props.callback('adv')}> Apply </LoadingButton>
            </Grid>
            <Grid item xs={3}/>
        </Grid>
        </Box>
    
    )
}

export default AppConfigAdvancedInputs