/*
 * Date: 2024
 * Description: App config
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Grid , Box , Typography , Accordion , AccordionDetails , AccordionSummary} from "@mui/material"
import { i_file_uploads_props, i_initial_props, i_snack_alert , i_alert_props } from "../../interfaces/utility.interface";
import { ReactElement , useState , useEffect , useContext } from "react";
import { useNavigate } from "react-router-dom";

//Context
import { ThemeContext } from "../../context/context";
import { MainContext } from "../../context/context";

//Components
import Footer from "../../components/utility/footer";
import AlertDialog from "../../components/utility/alert";
import AppConfigInputs from "../../components/settings/app.config/app.config.inputs";
import UploadsInputs from "../../components/utility/upload.box";
import CircularUnderLoad from "../../components/utility/center.loader";
import AppConfigAdvancedInputs from "../../components/settings/app.config/app.advanced.config.input";

//Utilitys
import { CheckEnv, f_empty_promise } from "../../utils/utility";
import { f_fetch, f_fetch_multiform } from "../../api/fetch";

//Constants
import { default_org ,org_errors ,adv_config_errors } from "../../utils/constant";

//Interfaces && types
import { i_app_config_errors, i_app_config_form , i_app_config, i_adv_config_errors, i_adv_config_form } from "../../interfaces/setting.interface";

//Middlewares
import { m_validate_app_config } from "../../validation/main.middleware";
import { m_validate_email_opt, m_validate_hex_colors, m_validate_opt_phone, m_validate_str , m_force_str , m_validate_opt_zip_code} from "../../validation/utility.middleware";

//Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const AppConfig = ( props : i_initial_props ) : ReactElement => {
    //Context
    const { mode , setCurrentColor } = useContext(ThemeContext);
    const [ open_setting , setOpenSetting ] = useState<boolean>(true)
    const [ open_adv_setting , setOpenAdvSetting ] = useState<boolean>(false)
    const { HandleLogout , setCurrentConfig } = useContext(MainContext);
    const nav = useNavigate()
    //Main states
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ target , setTarget ] = useState(default_org)
    const [ api_errors, setApiErrors ] = useState<i_snack_alert>({ open : false , promise : f_empty_promise()})

    //Business fields
    const [ app_config , setAppConfig ] = useState<i_app_config>(default_org)
    const [ adv_config , setAdvConfig ] = useState<i_app_config>(default_org)
    const [ upload , setUpload ] = useState<any[]>([])
    const [ asset, setAsset ] = useState<string[]>([]);
    //Errors 
    const [ app_config_errors , setAppConfigErrors ] = useState<i_app_config_errors>(org_errors)
    const [ adv_errors , setAdvConfigErrors ] = useState<i_adv_config_errors>(adv_config_errors)
    const [ Eupload, setEupload ] = useState<string>("")
    const handleSubmit = async( type : 'adv' | 'simple') => {
        //validation here
        const [ valid , error , field ] = m_validate_app_config(app_config , type)
        if(valid){
            setApiErrors({ open : false, promise : f_empty_promise()})
            const formdata = new FormData()
            if(type === 'simple'){
                
                formdata.append('_id', app_config._id)
                formdata.append('id', app_config.id)
                formdata.append('name',app_config.name)
                formdata.append('url', app_config.url)
                formdata.append('email', app_config.email)
                formdata.append('country', app_config.country)
                formdata.append('activity', app_config.activity)
                formdata.append('door_number', app_config.door_number)
                formdata.append('unit_number', app_config.unit_number)
                formdata.append('street', app_config.street)
                formdata.append('city', app_config.city)
                formdata.append('state', app_config.state)
                formdata.append('zip', app_config.zip)
                formdata.append('phone', app_config.phone)
                formdata.append('phone_two', app_config.phone_two)
                formdata.append('primary_color', app_config.primary_color)
                formdata.append('secondary_color', app_config.secondary_color)
                formdata.append('hover_color', app_config.hover_color)
                formdata.append('selected_color', app_config.selected_color)
                if(upload.length > 0) formdata.append('files', upload[0])
                if(asset.length > 0)formdata.append('path[]', asset[0])
            }else{
            }
            const res = type === 'simple' ? await f_fetch_multiform('/config/' + type, 'PUT', formdata ) : await f_fetch('/config/' + type, 'PUT', true, adv_config )
            if(res.type === 'Success'){
                if(type === 'simple'){
                    setCurrentColor(res.data)
                    setCurrentConfig(res.data)
                    setTarget(res.data)
                    setUpload([])
                }
                setApiErrors({ open : true, promise : res })
            }
            if(res.type === 'Unauthorized') HandleLogout(nav)
            else{
                setApiErrors({ open : true, promise : res })
            }
        }else{
            setAppConfigErrors({...app_config_errors, [field] : error})
        }
    }

    const app_config_form_props : i_app_config_form = {
        form : app_config,
        setForm : setAppConfig,
        errors : app_config_errors,
        mode : mode,
        loading : loading,
        callback : handleSubmit,
        dev : dev,
    }
    const adv_config_form_props : i_adv_config_form = {
        form : adv_config,
        setForm : setAdvConfig,
        errors : adv_errors,
        mode : mode,
        loading : loading,
        callback : handleSubmit,
        dev : dev,
    }
    const uploads_props : i_file_uploads_props = {
        uploads : upload,
        setUploads : setUpload,
        assets : asset,
        setAssets : setAsset,
        max : 1,
        Euploads : Eupload,
        setEuploads : setEupload,
        type : 'form',
        title : "Organization Logo",
        path : '/api/web/public/config/',
        setApiError : setApiErrors,
        mobile : props.mobile
    }
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : f_empty_promise()}),
        type : 'simple',
        mobile : props.mobile
    }
    useEffect(() => {
        setLoading(true);
        const findOrg = async () => {
            const res = await f_fetch('/config', 'GET', true, null)
            if(res.type === 'Success'){
                setUpload([])
                setTarget(res.data)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
                else{
                    setApiErrors({ open : true , promise : res })
                }
            }
            setLoading(false)
        }
        findOrg()
    },[HandleLogout , nav])

    useEffect(() => {
        setAppConfig({...target})
        setAdvConfig({...target})
        setAsset(target.path === '' || target.path === null ? [] : [target.path])
    },[target])
    
    useEffect(() => {
        const F = app_config
        const E = app_config_errors
        if(E.name !== '' && m_force_str(F.name) === '') setAppConfigErrors({...E, name : ''})
        if(E.email!== '' && m_validate_email_opt(F.email)) setAppConfigErrors({...E, email : ''})
        if(E.phone !== '' && m_validate_opt_phone(F.phone)) setAppConfigErrors({...E, phone : ''})
        if(E.phone_two !== '' && m_validate_opt_phone(F.phone_two)) setAppConfigErrors({...E, phone_two : ''})
        if(E.unit_number!== '' && m_validate_str(F.unit_number)) setAppConfigErrors({...E, unit_number : ''})
        if(E.door_number!== '' && m_validate_str(F.door_number)) setAppConfigErrors({...E, door_number : ''})
        if(E.street!== '' && m_validate_str(F.street)) setAppConfigErrors({...E, street : ''})
        if(E.city!== '' && m_validate_str(F.city)) setAppConfigErrors({...E, city : ''})
        if(E.state!== '' && m_validate_str(F.state)) setAppConfigErrors({...E, state : ''})
        if(E.zip!== '' && m_validate_opt_zip_code(F.zip)) setAppConfigErrors({...E, zip : ''})
        if(E.primary_color && m_validate_hex_colors(F.primary_color)) setAppConfigErrors({...E, primary_color : ''})
        if(E.secondary_color && m_validate_hex_colors(F.secondary_color)) setAppConfigErrors({...E, secondary_color : ''})
        if(E.hover_color && m_validate_hex_colors(F.hover_color)) setAppConfigErrors({...E, hover_color : ''})
        if(E.selected_color && m_validate_hex_colors(F.selected_color)) setAppConfigErrors({...E, selected_color : ''})
      },[app_config , app_config_errors])
    return (
        <Box>
            <Box sx={{ minHeight : '91vh'}}>
                {loading ? <CircularUnderLoad type={'full'} /> : 
                    <Grid container mt={6}>
                        <Grid item xs={0} sm={0.5} md={1} lg={2} xl={3}/>
                        <Grid item xs={12} sm={11} md={10} lg={8} xl={6}>
                            <Accordion sx={{ marginLeft : 'auto' , marginRight : 'auto'}} expanded={open_setting} onChange={() => setOpenSetting(open_setting ? false : true)}>
                                <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel-settings" id="_settings">
                                    <Typography sx={{ fontSize : 20 , fontWeight : 800}}>App settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box p={2} m={2} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                        <Grid container>
                                            <Grid item xs={1}/>
                                                <Grid item xs={10}>
                                                    <UploadsInputs {...uploads_props}></UploadsInputs>
                                                </Grid>
                                            <Grid item xs={1}/>
                                        </Grid>
                                    </Box>
                                    <AppConfigInputs { ...app_config_form_props } />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={0} sm={0.5} md={1} lg={2} xl={3}/>
                        <Grid item xs={0} sm={0.5} md={1} lg={2} xl={3}/>
                        <Grid item xs={12} sm={11} md={10} lg={8} xl={6} mt={2} mb={4}>
                            <Accordion sx={{ marginLeft : 'auto' , marginRight : 'auto'}} expanded={open_adv_setting} onChange={() => setOpenAdvSetting(open_adv_setting ? false : true)}>
                                <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel-adv-settings" id="_adv_settings">
                                    <Typography sx={{ fontSize : 20 , fontWeight : 800}}>Advanced settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AppConfigAdvancedInputs {...adv_config_form_props}  />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={0} sm={0.5} md={1} lg={2} xl={3}/>
                    </Grid>
                }
            </Box>
            <AlertDialog {...alert_props} />
            <Footer type={'center'} {...props}/>
        </Box>
    )
}

export default AppConfig;