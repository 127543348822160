

/*
 * Date: 2024
 * Description: Basic events logs of the current day
 * Author: Philippe Leroux @ skitsc
 */


//Modules
import { Grid, IconButton, Typography , Box } from "@mui/material"

//Interfaces && types
import { i_punch, i_punch_display_props } from "../../interfaces/timesheet.interface"

//Utilitys
import { f_timestamp_to_date } from "../../utils/utility"

//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import WcIcon from '@mui/icons-material/Wc';
import OutputIcon from '@mui/icons-material/Output';
//Styles
import { header_text , header_style , row_style , row_text } from "../../styles/tbl.styles";

const PunchDisplay = ( props : i_punch_display_props ) => {
    const T = props.type === 'Admin' ? 4.75 : 5.5
    const handleCallback = ( row : i_punch , type : 'view' | 'form' | 'disable') => {
        if(props.callback!== undefined) props.callback(type, row)
    }
    return (
            <Grid container sx={{ width : '100%' , border : '1px solid gray', mx : 'auto' , borderTopRightRadius : '8px' , borderTopLeftRadius : '8px' , maxHeight : props.type === 'Admin' ? 'initial' : '600px' , overflowY : 'scroll' }}>
                {props.type !== 'Admin' &&
                    <Grid item xs={12} sx={{ borderBottom : '1px solid gray'}}>
                        <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Today timesheet</Typography>
                    </Grid>
                }
                <Grid item xs={T} sx={ header_style }>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Start </Typography>
                </Grid>
                <Grid item xs={T} sx={header_style}>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> End </Typography>
                </Grid>
                <Grid item xs={1} sx={header_style}>
                    <Typography sx={[header_text , props.mobile && {fontSize : 18} ]}> Type</Typography>
                </Grid>
                { props.type === 'Admin' &&
                    <Grid item xs={1.5} sx={{  borderBottom : '1px solid gray'}}>
                        <Typography sx={header_text}>Action</Typography>
                    </Grid>
                }
                    {props.data.map((punch : i_punch , index : number) => (
                        <Grid container key={index}  sx={{ display : 'flex' , borderBottom : '1px solid gray'}}>
                            <Grid item p={1} xs={T} sx={row_style}> <Typography sx={row_text}>{ f_timestamp_to_date( punch.punch_in , 'long' )}</Typography></Grid>
                            <Grid item p={1} xs={T} sx={row_style}> <Typography sx={row_text}> { punch.punch_out !== 0  ? f_timestamp_to_date( punch.punch_out , 'long') : '.. ongoing'}</Typography></Grid>
                            <Grid item p={1} xs={1} sx={row_style}><Box sx={{ display : 'flex' , justifyContent : 'center'}}>{punch.type === 'in' ? <PunchClockIcon sx={{ color : 'lime' }}/> : punch.type === 'lunch' ?
                             <LunchDiningIcon sx={{ color : 'cyan'}} /> : punch.type === 'break' ? <WcIcon sx={{ color : 'blue' }}/> : <OutputIcon/> } </Box></Grid>
                            { props.type === 'Admin' &&
                                <Grid item xs={1.5} sx={{ display : 'flex' , justifyContent : 'space-between'}}>
                                    <IconButton sx={{ color : 'purple' , marginRight : 'auto'}} onClick={() => handleCallback(punch , 'view')}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleCallback(punch , 'form')}>
                                        <EditIcon sx={{}}/>
                                    </IconButton>
                                    <IconButton onClick={() => handleCallback(punch , 'disable')} sx={{ marginLeft : 'auto'}}>
                                        <DeleteForeverIcon sx={{ color : 'red' }}/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>   

                    ))}
            </Grid>
    )
}


export default PunchDisplay