/*
 * Date: 2024
 * Description: Notifications page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box, Typography } from "@mui/material"
import { ReactElement, useContext , useEffect , useState , ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"

//Interfaces && types
import { i_initial_props, i_promise, i_search_filter, i_table , i_tbl_header , i_basic_search_props , i_top_tbl_bar , i_pagination_new, i_socket_response } from "../interfaces/utility.interface"

//Components
import Footer from "../components/utility/footer"
import AlertDialog from "../components/utility/alert"
import SearchFilter from "../components/utility/inputs/search.filter"
import Tbl from "../components/table/table"
import TopTableBar from "../components/utility/top.table.bar"

//Styles
import { inner_container, secondary_container } from "../styles/main.styles"
import { header_row , tbl_boxing } from "../styles/tbl.styles"


//API & Utils
import { f_fetch } from "../api/fetch"
import { MainContext } from "../context/context"
import { SocketContext, useSocketEvent } from "../context/socket.context"
import { default_filter , empty_promise } from "../utils/constant"
import { f_encode_query_data } from "../utils/utility"

//Interfaces
import { i_notif } from "../interfaces/notif.interface"
import { i_snack_alert , i_alert_props } from "../interfaces/utility.interface"
import { i_user } from "../interfaces/user.interface"

const Notifications = ( props : i_initial_props ) : ReactElement => {
    const socket = useContext(SocketContext)
    const { HandleLogout , user} = useContext(MainContext)
    const [ data , setData ] = useState<i_notif[]>([])
    const [ pairs , setPairs ] = useState<i_user[]>([])

    const [ search, setSearch ] = useState<string>('')
    const [ count, setCount ] = useState<number>(0)
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter)
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    const [ refresh, setRefresh ] = useState<number>(1)
    const [ channel ] = useState(user.type === 'Admin' ? 'notification_notif' : user._id + '_notif')
    const nav = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const clean_filter : i_search_filter = user.type === 'User' ?  {...filter , status : '1'} : {...filter , admin_status : '1'}
            const encode = f_encode_query_data(clean_filter)
            const path = user.type === 'Admin' ? '/admin?' : '/filtered?'
            const res : i_promise = await f_fetch('/notifs' + path +encode, 'GET', true, null)
            if(res.type === 'Success'){
                setData(res.data.notifs)
                setCount(res.data.count)
                if(user.type === 'Admin') setPairs(res.data.users)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
                setApiError({open : true, promise : res})
            }
            setLoading(false)
        }
        fetchData()
    },[ nav , HandleLogout , filter , user.type , refresh])
    useSocketEvent( channel, (output : i_socket_response) => {
        if(output.type === 'Update'){
            setRefresh(refresh + 1)
        }
    })

    const handleRow = async(row : i_notif) => {
        const res = await f_fetch('/notifs/' + row._id , 'PATCH', true, null)
        if(res.type === 'Success'){
            setRefresh(refresh + 1)
        }else{
            if(res.type === 'Unauthorized') HandleLogout(nav)
            setApiError({open : true, promise : res})
        }
    }
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Notifications'
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : '',
        count : data.length
    }
    const table_tool_bar_props : i_top_tbl_bar = {
        title : 'Notifications',
        callback : () => {},
        inputs : [<SearchFilter {...search_input_props} />],
        count : data.length,
        add : false
    }
    const user_headers : i_tbl_header[] = [
        { value : "Description" , css : { ...header_row } , portion : 7 },
        { value : "Type" , css : { ...header_row } , portion : 2 },
        { value : "Date added" , css : { ...header_row, } , portion : 2 , },
        { value : 'Action', css : header_row, portion :1  }
    ]
    const user_admin_headers : i_tbl_header[] = [        
        { value : "User" , css : { ...header_row } , portion : 2 },
        { value : "Description" , css : { ...header_row } , portion : 5 },
        { value : "Type" , css : { ...header_row } , portion : 1 },        
        { value : "Status" , css : { ...header_row } , portion : 1 },
        { value : "Date added" , css : { ...header_row, } , portion : 2 , },
        { value : 'Action', css : header_row, portion :1  },        


    ]
    const table_v2_props : i_table = {
        data : data,
        pairs : pairs,
        title : 'No notifications found',
        loading : loading,
        headers : user.type === 'Admin' ? user_admin_headers : user_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "notif",
        pagination : pagination_props,
        user : user
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'full',
        mobile : props.mobile
    }
    return (
        <Box>
            <Box sx={secondary_container}>
                <Box sx={inner_container}>

                    <Box sx={{ padding: '24px'}}>
                        { data.length > 0 ?
                            <TopTableBar {...table_tool_bar_props}/> 
                            :
                            <Box sx={{ marginLeft : 'auto' , marginRight : 'auto' , textAlign : 'center'}}>
                                <Typography variant="h4">Notifications</Typography>
                            </Box>
                        }   
                        <Box sx={tbl_boxing}>
                            <Tbl {...table_v2_props} />
                        </Box>
                    </Box>
                </Box>

            </Box>
            <AlertDialog {...alert_props}/>
            <Footer type={'center'} {...props}/>
        </Box>
    )
}


export default Notifications