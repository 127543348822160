
/*
 * Date: 2024
 * Description: Reusable table remastered
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { ReactElement } from "react";
import { Box , Typography } from "@mui/material";

//Interfaces
import { i_table } from "../../interfaces/utility.interface";

//Components
import HeaderV2 from "./header.v2";
import UserRow from "../settings/users/table/users.rows";
import TablePaginationV2 from "./pagination";
import LogsRow from "../settings/logs/table/logs.rows";
import UserPunchRow from "../settings/users/table/user.punch.rows";
import NotifRow from "../notif/notif.rows";
import AdminNotifRow from "../notif/admin.notifs.rows";

const Tbl = ( props : i_table ) : ReactElement => {
    const value = props.user !== undefined ? props.user : ""
    return (
        <Box>
            {  props.data.length === 0 ?
                <Box sx={{ marginTop : 20 , padding : 10 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , textAlign : 'center' , border: '1px solid gray' , maxWidth : '500px'}}>
                    <Typography sx={{ fontSize : 24 , fontWeight : 800}}>No new {props.row_model} yet!</Typography>
                </Box>
            :
            <Box>
                <HeaderV2 headers={props.headers}/>
                {  props.data.map(( row : any , index : number) => (
                    <Box key={index}>
                        { props.row_model === 'user' &&
                            <UserRow row={row} {...props}/>
                        }
                        { props.row_model === 'user_punch' &&
                            <UserPunchRow row={row} {...props}/>
                        }
                        { props.row_model === 'logs' &&
                            <LogsRow row={row} {...props}/>
                        }
                        {
                            props.row_model === 'notif' && value !== ''  && value.type === 'Admin' &&  
                                <AdminNotifRow row={row} {...props}/>
                        }
                        {
                            props.row_model === 'notif' && value !== ''  && value.type === 'User' &&  row.status === 1 &&
                                <NotifRow row={row}{...props}/>
                        }
                    </Box>
                ))}
            
            {props.pagination !== undefined &&
                <TablePaginationV2 {...props.pagination} />
            }
            </Box>
        }
      </Box>
    )

}

export default Tbl;