

/*
 * Date: 2024
 * Description: Private ( user side..) punch page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box , Button , Grid, Modal } from "@mui/material"
import { useEffect , useState , useContext } from "react"
import { useNavigate } from "react-router-dom"

//Interfaces && types
import { i_initial_props , i_promise , i_current_time ,  i_snack_alert , i_alert_props , i_socket_response, i_prompt_modal_props } from "../interfaces/utility.interface"
import { i_punch, i_punch_display_props, i_punch_errors, i_timesheet_complete, i_timesheet_filter, i_timesheet_table_props, t_punch_type } from "../interfaces/timesheet.interface"

//Components
import Footer from "../components/utility/footer"
import PunchDisplay from "../components/punch/punch.display"
import AlertDialog from "../components/utility/alert"
import TimeSheetDisplay from "../components/punch/table/timesheet.display"
import ModalPromptBody from "../components/modal/modal.prompt"
import TimeDisplay from "../components/punch/time.display"

//Context
import { MainContext , ThemeContext } from "../context/context"
import { SocketContext, useSocketEvent } from "../context/socket.context"

//Utility
import { f_fetch, f_fetch_multiform , takePhotoFromCamera } from "../api/fetch"
import { getPayAllPeriods , getPayPeriodFromDate , formatTime } from "../utils/utility"

//Constant
import { default_punch , default_punch_errors , default_timesheet_complete, empty_promise } from "../utils/constant"

//Middlewares
import { m_validate_punch } from "../validation/main.middleware"


const Punch = ( props : i_initial_props ) => {

    const initial = () => {
        const periods = getPayAllPeriods(new Date().getFullYear(), true);
        const period = getPayPeriodFromDate(periods, new Date().valueOf());
        return { period : period? period.period : 0 , user_id : '' }
    }
    const socket = useContext(SocketContext)
    const { mode } = useContext(ThemeContext)
    const { HandleLogout , user , config , setCurrentConfig } = useContext(MainContext)
    const [ current_punch , setCurrentPunch ] = useState<i_punch>(default_punch)
    const initialTime = current_punch.punch_in !== 0 ? new Date(current_punch.punch_in * 1000) : new Date();
    const [ time, setTime] = useState<string>(formatTime(initialTime));
    const [ data , setData ] = useState<i_timesheet_complete>(default_timesheet_complete)
    const [ open , setOpen ] = useState<boolean>(false)
    const [ type , setType ] = useState<t_punch_type>('in')
    const [ day_punch , setDayPunch ] = useState<i_punch[]>([])
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ e_punch ,setEPunch ] = useState<i_punch_errors>(default_punch_errors)
    const [ modal_title , setModalTitle ] = useState<string>('')
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    const [ filter , setFilter ] = useState<i_timesheet_filter>(initial())
    const [ channel ] = useState(user.type === 'Admin' ? 'punch' : user._id + '_punch')
    const [ isInactive, setIsInactive ] = useState<boolean>(false);

    const inactivityTime : number = 900000; 
    const nav = useNavigate()
    const handleCallback = async() => {
        setLoading(true)
        const clean_punch = {...current_punch}
        clean_punch.type = type
        const [ valid , field , message ] = m_validate_punch(clean_punch)
        if(valid){
            const form_data = new FormData()
            if(config.force_picture){
                var blob = await takePhotoFromCamera()
                if(blob !== null)
                form_data.append('files', blob , 'photo.jpg')
            }
            form_data.append('type', type)
            const res = await f_fetch_multiform('/timesheet/punch' , 'POST' , form_data)
            if(res.type === 'Success'){
                setCurrentPunch(res.data)
                setOpen(false)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
                else setApiError({open : true, promise : res})
            }
        }else{
            setEPunch({...e_punch,[field] : message})
        }
        setLoading(false)
    }
    const handleTransition = (type : t_punch_type) => {
        setType(type)
        setOpen(true)
    }
    useSocketEvent(channel , (output : i_socket_response ) => {
        if (output.type === 'Update') {
            setDayPunch((prevDayPunch) => {
                return prevDayPunch.map((item: i_punch) =>
                    item._id === output.item._id ? output.item : item
                );
            });
            setCurrentPunch(output.item)
            if(output.item.type === 'in'  && output.item.punch_out !== 0) setCurrentPunch({...default_punch})
        }
        if (output.type === 'Add') {
            setDayPunch((prevDayPunch) => {
                const objectExists = prevDayPunch.some((obj: i_punch) => obj._id === output.item._id);
                if (!objectExists) {
                    return [...prevDayPunch, output.item];
                }
                return prevDayPunch;
            });
            setCurrentPunch(output.item)
        }
    })
    useSocketEvent('config' , (output : i_socket_response ) => {
        if(output.type === 'Update') setCurrentConfig({ ...config,  ...output.item })
    })
    useEffect(() => {
        setLoading(true)
        const getTimes = async() : Promise<i_promise> => {
            const res = await f_fetch('/timesheet' , 'GET' , true , null)
            if(res.type === 'Success'){
                if (res.data.timesheet[0].punches.length > 0) {
                    const today = new Date().toISOString().split('T')[0];
                    const new_punch_arr: i_punch[] = [];
                
                    for (let x = 0; x < res.data.timesheet[0].punches.length; x++) {
                        const punchDate = new Date(res.data.timesheet[0].punches[x].punch_in * 1000).toISOString().split('T')[0];
                        //This should fix the issue where a not close punch from the last day is still open if cron is not running.
                        if (punchDate === today || res.data.timesheet[0].punches[x].punch_out === 0) new_punch_arr.push(res.data.timesheet[0].punches[x]);
                    }
                
                    if (new_punch_arr.length > 0) {
                        if (new_punch_arr[0].punch_out === 0)    setCurrentPunch(new_punch_arr[0]);
                        setDayPunch(new_punch_arr.reverse());
                    } else {
                        console.log("No punches for today...");
                    }
                } else {
                }
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
            return res
        }
        getTimes()
    },[nav , HandleLogout])
    useEffect(() => {
        const getTimesheetByPeriods = async() => {
            const res = await f_fetch('/timesheet/period/'+filter.period , 'GET' , true , null)
            if(res.type === 'Success'){
                const complete_timesheet : i_timesheet_complete = { ...res.data.timesheet, punches : res.data.punches}
                setData(complete_timesheet)
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
        }
        getTimesheetByPeriods()
    },[filter , nav , HandleLogout])

    useEffect(() => {
        const return_title = () : string => {
            if(current_punch.type === 'in' && type === 'break' ) return 'Start a break'
            if(current_punch.type === 'in' && type === 'lunch' ) return 'Start lunch time'
            if(current_punch.type === 'in' && current_punch.punch_in === 0) return 'Begin your shift'
            if(current_punch.type === 'in' && current_punch.punch_in !== 0) return 'End your shift'
            if(current_punch.type === 'lunch') return 'End lunch time'
            if(current_punch.type === 'break') return 'End your break'
                return 'else ?'
        }
        setModalTitle(return_title())
    },[type , setModalTitle , current_punch])
    useEffect(() => {
        let timeout : NodeJS.Timeout
        const resetTimer = () => {
          clearTimeout(timeout);
          setIsInactive(false);
          timeout = setTimeout(() => setIsInactive(true), inactivityTime);
        };
        const events = ['mousemove', 'keydown', 'scroll', 'click'];
        events.forEach((event) => {
          window.addEventListener(event, resetTimer);
        });
        timeout = setTimeout(() => setIsInactive(true), inactivityTime);
        return () => {
          events.forEach((event) => {
            window.removeEventListener(event, resetTimer);
          });
          clearTimeout(timeout);
        };
      }, []);
    useEffect(() => {
        if (isInactive) HandleLogout(nav)
    }, [isInactive , HandleLogout , nav]);
    const modal_prompt_props : i_prompt_modal_props = {
        open : open,
        loading : loading,
        callback : handleCallback,
        mode : mode,
        type : 'update',
        handleClose : () => setOpen(false),
        title : modal_title,
        mobile : props.mobile,
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'full',
        mobile : props.mobile
    }
    const punch_display_props : i_punch_display_props = {
        data : day_punch,
        type : user.type,
        mobile : props.mobile
    }
    const timesheet_tbl_props : i_timesheet_table_props = {
        data : data,
        loading : loading,
        filter : filter,
        setFilter : setFilter,
        type : user.type,
        mobile : props.mobile
    }

    const current_time_props : i_current_time = {
        time : time,
        setTime : setTime,
        timestamp : current_punch.punch_in,
    }
    return ( 
        <Box>
            <Box sx={{ minHeight : '87.8vh'}}>
                <Box sx={{ marginLeft : 'auto' , marginRight : 'auto', marginTop : '3vh' , maxWidth : '1000px' , padding : props.mobile ? 2 : 0}}>
                    <Grid container>
                            <Grid item xs={12}>
                                <TimeDisplay {...current_time_props} />
                                <Button variant={'contained'} onClick={() => handleTransition('in')} sx={{ backgroundColor: current_punch.punch_in === 0  ? 'lime' : 'red', display : 'block', fontSize : 22 , marginLeft : 'auto' , marginRight : 'auto'}}>
                                    {current_punch.type === 'in' && current_punch.punch_in === 0 ? "Punch In !" : current_punch.type === 'in' && current_punch.punch_in !== 0 ? 'Punch out!' : current_punch.type === 'lunch' ? 'Finish lunch' : current_punch.type === 'break'? 'Finish break' : 'Punch In!'}
                                </Button>
                                {current_punch.type === 'in' && current_punch.punch_in !== 0 &&
                                    <Box sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '2vh' , marginBottom : '1vh' , gap : '20px'}}>
                                        <Button variant={'contained'} sx={{ backgroundColor : 'blue'}} onClick={() => handleTransition('break')}>
                                            Break
                                        </Button>
                                        <Button variant={'contained'} sx={{ backgroundColor : 'cyan'}} onClick={() => handleTransition('lunch')}>
                                            Lunch
                                        </Button>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                {day_punch.length > 0 &&
                                    <PunchDisplay {...punch_display_props}/>
                                }
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TimeSheetDisplay {...timesheet_tbl_props}/>
                            </Grid>
                    </Grid>
                </Box>
            </Box>
            <AlertDialog {...alert_props}/>
            <Modal open={open} onClose={() => setOpen(false)} children={<ModalPromptBody {...modal_prompt_props} />} />
            <Footer type={'center'} {...props}/>
        </Box>
    )
}

export default Punch