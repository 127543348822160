/*
 * Date: 2024
 * Description: Top bar menu for extra interactions
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useContext , useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, useTheme , Tooltip , Menu , MenuItem , Badge} from "@mui/material";
import { Link  } from "react-router-dom";

//Context
import { MainContext, ThemeContext } from "../../context/context";
import { useSocketEvent } from "../../context/socket.context";

//Icons
import { LightModeOutlined , DarkModeOutlined } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

//Utils
import { f_set_local_key } from "../../utils/utility";
import { f_fetch } from "../../api/fetch";

//Interfaces && types
import { i_initial_props, i_promise , i_socket_response } from "../../interfaces/utility.interface";


const TopBar = ( props : i_initial_props ) => {
    const theme = useTheme();
    const nav = useNavigate()
    const { user , HandleLogout } = useContext(MainContext)
    const [ notif , setNotif ] = useState<number>(0);
    const [ anchor, setAnchor] = useState<null | HTMLElement>(null);
    const { mode , setCurrentTheme , primary_color , secondary_color} = useContext(ThemeContext)
    const [ mobileOpen , setMobileOpen ] = useState<boolean>(false);
    const [ selected , setSelected ] = useState<string>(window.location.pathname);
    const [ refresh , setRefresh ] = useState<number>(1)
    const [ channel ] = useState(user.type === 'Admin' ? 'notification_notif' : user._id + '_notif')
    const handleMode = () => {
        mode === 'Light'? setCurrentTheme('Dark') : setCurrentTheme('Light')
        mode === 'Light'? f_set_local_key('theme_mode','Dark' , 2592000000) : f_set_local_key('theme_mode','Light' , 2592000000)
    } 
    const sizing = {
        width : '3vh',
        height : '3vh',
    }
    useSocketEvent(channel, ( output : i_socket_response ) => {
        if (output.type === 'Update')    setRefresh(refresh + 1)
    })
    useEffect(() => {
        if(window.location.pathname !== selected) setSelected(window.location.pathname)
    },[window.location.pathname , selected])
    const handleMenu = ( event: React.MouseEvent<HTMLButtonElement> ) => {
        setAnchor(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchor(null);
    };
    const open = Boolean(anchor);
    useEffect(() => {
        if(!props.mobile && mobileOpen) setMobileOpen(false)
    },[props.mobile , mobileOpen])
    useEffect(() => {
        const getNotif = async() : Promise<i_promise> => {
            const res = await f_fetch('/notifs/cnt' , 'GET' , true , null )
            if(res.type === 'Unauthorized') HandleLogout(nav)
            return res
        }
        const handleNotif = async() => {
            const res = await getNotif()
            if(res.type === 'Success'){
                setNotif(res.data)
            }
        }
        handleNotif()
    },[nav , HandleLogout , refresh])
  return (
    <Box>

        <Box sx={{ display : 'flex'  , borderBottom: "1px solid #EAECF0" ,  height : '5vh' , paddingRight : '10px' , paddingTop : '2.5px' , paddingLeft : '10px'}} >
            <IconButton component={Link} to={'/overview'} sx={{ marginRight : '1vh'}}>
                <HomeIcon sx={sizing}  color={selected === '/overview' ? 'secondary' :"primary"}/>
            </IconButton>
            {user.type === 'Admin' && 
                <>
                    <IconButton component={Link} to={'/settings/users'}>
                        <GroupIcon sx={sizing} color={selected === '/settings/users' ? 'secondary' :"primary"}/>
                    </IconButton>
                    <IconButton component={Link} to={'/timesheets'}>
                        <LibraryBooksIcon sx={sizing} color={selected === '/timesheets' ? 'secondary' :"primary"}/>
                    </IconButton>
                </>    
            }
            {user.type === 'User' && !props.mobile &&
                <Box sx={{ alignSelf : 'center' , display : 'flex'}}><Box sx={{ marginRight : '8px'}}>Welcome back</Box> <Box sx={{ fontWeight : 800}}>{user.first_name + " " + user.last_name}</Box></Box>
            }
            <Box sx={{ marginLeft : 'auto'}}>
                <Tooltip title={theme.palette.mode + " Mode"}>
                    <IconButton  color='primary' sx={{ marginRight : '1vh'}} onClick={ () => handleMode() }>
                    {mode === 'Dark' ? (
                        <DarkModeOutlined sx={[{ color : primary_color} , sizing ]}/>
                    ) : (
                        <LightModeOutlined color="primary" sx={sizing} />
                    )}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Notifications">
                    <IconButton component={Link} sx={[sizing , { marginRight : '1vh'}]} color='primary' to={'/notif'}>
                        {notif > 0 ? 
                            <Badge badgeContent={notif} color={'secondary'}>
                                <NotificationsIcon sx={sizing} color={selected === '/notif' ? 'secondary' :"primary"}/>
                            </Badge>
                                :
                            <NotificationsIcon sx={sizing} color={selected === '/notif' ? 'secondary' :"primary"}/>
                        }
                    </IconButton>
                </Tooltip>
                <IconButton id="_setting_icon" color='primary' aria-controls={open ? 'setting-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}onClick={handleMenu} >             
                    <SettingsIcon sx={sizing} />
                </IconButton>
                <Menu id="_menu"anchorEl={anchor} open={Boolean(anchor)}onClose={handleCloseMenu}MenuListProps={{'aria-labelledby': '_setting_icon'}} >
           
                    <MenuItem component={Link} onClick={handleCloseMenu} to={'/profile'} sx={{ backgroundColor : selected === '/profile' ? secondary_color : mode === "Light" ? "white" : "initial"}}>Profile</MenuItem>
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/app.config'}sx={{ backgroundColor : selected === '/settings/app.config' ? secondary_color : mode === "Light" ? "white" : "initial"}}>App settings</MenuItem> }
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/mail.config'} sx={{ backgroundColor : selected === '/settings/mail.config' ? secondary_color : mode === "Light" ? "white" : "initial"}}>Mail settings</MenuItem> }
                    {user.type === 'Admin' && <MenuItem onClick={handleCloseMenu} component={Link} to={'/settings/logs'} sx={{ backgroundColor : selected === '/settings/logs' ? secondary_color : mode === "Light" ? "white" : "initial"}}>Logs</MenuItem> }
                    <MenuItem component={Link} to={'/logout'}>Logout</MenuItem>
                </Menu>
            </Box>
        </Box>
    </Box>
  );
};

export default TopBar;

