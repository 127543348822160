
/*
 * Date: 2024
 * Description: Form content for app configuration
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ChangeEvent, ReactElement  } from "react";
import { Box , Grid, TextField , Typography , MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { HexColorPicker } from "react-colorful";
//Interfaces && types
import { i_app_config_form } from "../../../interfaces/setting.interface";
//Styles
import { sub_title } from "../../../styles/form.styles";
import { input_base } from "../../../styles/main.styles";

//Const
import { provinces , country} from "../../../utils/constant";

//Icons
import "../../../styles/color.css"

const AppConfigInputs = ( props : i_app_config_form ) : ReactElement => {
    const F = props.form
    const E = props.errors
    const handleChange = ( event : ChangeEvent<HTMLInputElement> ) => {
        const { name, value } = event.target;
        props.setForm({...F, [name] : value })
    }
    const title = { ...sub_title , marginRight : 'auto'}
    return ( 
    <Box component={"form"} p={2} m={2} sx={{ marginBottom : '2vh' , display : 'flex' , justifyContent : 'center'}} id={'app_config_inputs'} >
         <Grid container sx={{}}>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={title}>Organization name  *</Typography>
                <TextField sx={[input_base , { width : '540px'} ]} name={'name'} size="small" placeholder={"Business name.."} value={F.name} error={E.name !== ""} helperText={E.name}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>           
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={title}>Activity * </Typography>
                <TextField type={'text'} sx={[input_base , { width : '540px'} ]} name={'activity'} placeholder={"Activity sector.."} size="small" value={F.activity} error={E.activity!== ''} helperText={E.activity}
                onChange={handleChange}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>

            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={title}>Organization location</Typography>
                <TextField select sx={[input_base , { width : '540px'} ]} name={'country'} size="small" value={F.country} error={E.country!== ''} helperText={E.country}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }>
                    {country.map((val , index : number) => (
                        <MenuItem key={index} value={val.value}>
                            {val.value}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={[ title , { fontSize : 14}]}>Unit number / Civic number* / Street*</Typography>
                <TextField sx={[input_base ,{ width : '80px' } ]} name={'unit_number'} placeholder={"Unit number.."} size="small" value={F.unit_number} onChange={handleChange}
                helperText={E.unit_number} error={E.unit_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base ,{ width : '80px' } ]} name={'door_number'} placeholder={"Door number.."} size="small" value={F.door_number} onChange={handleChange}
                helperText={E.door_number} error={E.door_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base ,{ width : '380px' } ]} name={'street'} placeholder={"Street name.."} size="small" value={F.street} onChange={handleChange}
                helperText={E.street} error={E.street !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={[ title , { fontSize : 14}]}> City* / Province* / Postal code*</Typography>
                <TextField sx={[input_base ,{ width : '360px' } ]} name={'city'} placeholder={"City.."} size="small" value={F.city} onChange={handleChange}
                helperText={E.city} error={E.city!== ''}autoComplete={props.dev ? "off" : "new-password" }/> 
                <TextField sx={[input_base ,{ width : '80px' } ]} name={'state'}  value={F.state} size="small" onChange={handleChange}
                helperText={E.state} error={E.state!== ''}autoComplete={props.dev ? "off" : "new-password" } select> 
                    {provinces.map((provinces , index ) => (
                        <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                    ))}
                </TextField>
                <TextField sx={[input_base ,{ width : '100px' } ]} name={'zip'} placeholder={"Zip code.."} size="small" inputProps={{ maxLength: 7 }} value={F.zip} onChange={handleChange}
                helperText={E.zip} error={E.zip!== ''}autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={title}>Email</Typography>
                <TextField type={'email'} sx={[input_base , { width : '540px'} ]} name={'email'} size="small" value={F.email} error={E.email!== ''} helperText={E.email}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                <Typography sx={title}>Website url</Typography>
                <TextField type={'text'} sx={[input_base , { width : '540px'} ]} name={'url'} size="small" value={F.url} error={E.url !== ''} helperText={E.url}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}> 
                <Typography sx={title}>Phone / Additional Phone </Typography>
                <TextField type={'text'} sx={[input_base , { width : '270px'} ]} name={'phone'}  size="small" value={F.phone} error={E.phone !== ''} helperText={E.phone}
                onChange={handleChange} />
                <TextField type={'text'} sx={[input_base , { width : '270px'} ]} name={'phone_two'}  size="small" value={F.phone_two} error={E.phone_two !== ''} helperText={E.phone_two}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} >
                <Grid container >
                    <Grid item xs={1}/>
                    <Grid item xs={10} sx={{ display : 'flex' , justifyContent : 'center' }}>
                        <Typography sx={title} mt={3}>Primary color / Secondary color </Typography>
                        <Box sx={{ display : 'flex' , gap : 5 }}>
                            <HexColorPicker color={F.primary_color} onChange={(value : string) => props.setForm({...F , primary_color : value})} className="small" />
                            <HexColorPicker color={F.secondary_color} onChange={(value : string) => props.setForm({...F , secondary_color : value})} className="small"/>
                        </Box>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={1} mt={2}/>
                    <Grid item xs={10} mt={2} sx={{ display : 'flex' , justifyContent : 'center' }}>   
                        <Typography sx={title} mt={3}>Hover color / Selected color </Typography>
                        <Box sx={{display : 'flex' , gap : 5}}>
                            <HexColorPicker style={{ width : '270px'}} color={F.hover_color} onChange={(value : string) => props.setForm({...F , hover_color : value})} className="small"/>
                            <HexColorPicker color={F.selected_color} onChange={(value : string) => props.setForm({...F , selected_color : value})} className="small" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Grid> 
            <Grid item xs={3}/>
            <Grid item xs={6} mt={5}>
                <LoadingButton sx={{ display : 'block', border : '1px solid gray' , maxWidth : '540px' , marginLeft : 'auto' , marginRight : 'auto'}} loading={false} fullWidth variant="contained" color="primary" onClick={() =>props.callback('simple')}> Apply </LoadingButton>
            </Grid>
            <Grid item xs={3}/>
        </Grid>
        </Box>
    
    )
}

export default AppConfigInputs;