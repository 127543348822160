


/*
 * Date: 2024
 * Description: Notif rows for the table
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , IconButton , Tooltip } from "@mui/material"
import { ReactElement } from "react"


//Interfaces && types
import { i_table_v2_row_props } from "../../interfaces/utility.interface";

//Icons
import ClearIcon from '@mui/icons-material/Clear';

//Utils
import { f_timestamp_to_date} from "../../utils/utility";



const NotifRow = ( props : i_table_v2_row_props ) : ReactElement => {

    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>

            <Grid item xs={props.headers[0].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.content}</Typography>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center' , opacity : props.row.disabled ? 0.5 : 1}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.type}</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ opacity : props.row.disabled ? 0.5 : 1}}>
                <Box sx={{ display : 'flex' , marginTop : '0.4vh' ,opacity : props.row.disabled ? 0.5 : 1 }}>
                    <Typography sx={{ fontSize : 14 }}>{f_timestamp_to_date(props.row.timestamp , 'long')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center' }}>
                <Tooltip title={'Remove this notification'}>
                    <IconButton sx={{ opacity : 1}} onClick={() => props.callback(props.row , 'disable')}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
          
        </Grid>
    </Box>
    )
}

export default NotifRow