
/*
 * Date: 2023-07-17
 * Description: reusable styles.
 * Author: Philippe Leroux @ skitsc
 */

//Interfaces && types
import { t_mode } from "../types/types";
const app = {
    display : 'flex',
    position : 'relative'
}
const content = {
    height : '100%',
    minHeight : '100vh',
    width : '100%',
    fontFamily: "Source Sans Pro, sans-serif"
}
const Loaders_center = {
    position: "fixed",
    left: "50%",
    top: "50%",
}
const FormStyles = {
    textfield: {
      marginTop : '1vh',
      marginBottom : '1vh',
      boxShadow: 0,
    },
    errorfield: {
        '& p':{
            color:'red',
          },
        "&.MuiFormHelperText-root.Mui-error" :{
          color: "red"
        },
        marginTop : '1vh',
    },
    errors: {
        color: "red",
        textAlign: "center",
    },
    label : {
      marginTop : "0.5vh"
    },
    inline:{
        display:"inline"
    },
    input: {
        display: "none",
    },
  };
  const Scrollbar_style = {
    scrollbarColor: '#888 #f1f1f1',
    '&::-webkit-scrollbar': {
    width: 10,
    },
    '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
    background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
    }
}
const modal_style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}
const input_base = {
  pl: 1,
  marginBottom : '1vh',
  borderRadius: 1
}
const stack_aligned = {
  alignItems: "center",
  marginBottom : 2
}
const input_error = {
  color : 'red',
  paddingTop : 1
}
const display_flex_center = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width : "100%"
}
const alert_display = {
  position : 'fixed',
  left : '50%',
  right : '50%',
  top : '15%'
}
const modal_style_large = {
  modal : {
    display : 'block',
    marginTop : '10vh',
    marginLeft : 'auto',
    marginRight : 'auto',
    maxWidth: '1000px',
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "6px",
    zIndex: 1000,
    transform: 'initial'
  },
  modal_title:{
    fontFamily: "Monospace",
    margin: '1em'
  },

}
const modal_style_two = {
  modal : {
    position: "fixed",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 'fit-content',
    maxWidth: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "6px",
    zIndex: 1000,
  },
  form_button:{
        margin: '1em'
  },
  modal_title:{
    fontFamily: "Monospace",
    margin: '1em'
  },
  modal_button_right:{
    margin: '2em',
      float: "right"
  },
  modal_button_left:{
    margin: '2em',
    float: "left"
  },
  modal_text: {
      textAlign: "center",
      fontFamily: "Monospace",
      fontSize: 26,
  },
  modal_paper_delete: {
      position: 'absolute',
      backgroundColor: 'white',
      width: "auto",
      border: '2px solid #000',
      padding: "1em",

  },
  modal_paper_client: {
    position: 'absolute',
    backgroundColor: 'white',
    width : "55%",
    border: '2px solid #000',
    padding: '1em'
  },
  modal_prompt: {
    position: 'absolute',
    backgroundColor: 'white',
    maxWidth : "500px",
    border: '2px solid #000',
    padding: '2em'
  },
  modal_info: {
      textAlign: "center",
      fontFamily: "Monospace",
  }
}
const getModalStyle = ( mode : t_mode) => {
  const top = 30 
  const left = 50 
  return {
    top: `${top}%`,
    left: `${left}%`,
    backgroundColor: mode === 'Dark' ? '#222' : '#fff',
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const getBigModalStyle = ( mode : t_mode) => {
  return {
    backgroundColor: mode === 'Dark' ? '#222' : '#fff',
  };
}
const form_img_card_style = { display: "flex", justifyContent: "center", height : '90px' , width : '90px', alignItems: "center",  position: 'relative' , marginTop : '8px' }
const img_style_form = { maxWidth: 200, maxHeight: 100, display: 'block', width: 'auto', height: 'auto', cursor : 'help' };
const main_container = { overflowX : 'hidden' , overflowY : 'scroll' , scrollbarWidth : 'none',  msOverflowStyle : 'none'}
const secondary_container = {minHeight : '91vh' , display : 'flex' , justifyContent : 'center' }
const inner_container = { maxWidth : '1550px' , width : '100%'}
export { Loaders_center , FormStyles , Scrollbar_style , app , content , modal_style , input_base , main_container, form_img_card_style , img_style_form, secondary_container, inner_container,
   stack_aligned  , input_error , display_flex_center , alert_display , getModalStyle , modal_style_two , modal_style_large , getBigModalStyle}